@import './utils/theme';

.card {
  --padding: 7.5%;

  margin: auto;
  text-align: center;
  height: 100vh;
  padding: var(--padding);
  background: #fafafac0;

  & .input-field {
    margin: 2rem 0.5rem;
  }

  & button {
    width: calc(100% - var(--padding));
    margin-top: 2rem;
  }

  & img {
    width: 30%;
  }

  & h5 {
    margin-bottom: 3rem;
  }
}

.v-align {
  position: relative;
  top: 45vh;
  transform: translateY(-50%);
}

html::after {
  content: '';
  background-image: url('../img/login_backgruound.jpg');
  background-size: cover;
  opacity: 0.5;
  inset: 0;
  position: absolute;
  z-index: -1;
}

.logo-hide {
  display: none;
}

@media #{$medium-and-up} {
  .card {
    max-width: 425px;
    padding: 5rem;
    border-radius: 5%;
    top: 45vh;
    transform: translateY(-50%);
    height: auto;
    background: #fafafa;

    & button {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    & img {
      width: 30%;
    }

    & h5 {
      font-size: 2rem;
    }
  }

  .v-align {
    top: unset;
    transform: unset;
  }

  #toast-container {
    top: 5vh;
  }
}
